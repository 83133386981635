import React, { useContext } from "react";
import { Element } from "react-scroll";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import Image from "react-image-webp";
import ReactGA from "react-ga";
import { Context } from "../../components/Wrapper";
import "./AboutSection.css";

function AboutSection() {
  const context = useContext(Context);
  ReactGA.event({
    category: "cv",
    action: "download_cv",
  });

  return (
    <Element name="about">
      <section
        id="about"
        className="about-section section-padding bd-bottom"
        data-scroll-index="1"
      >
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-7 sm-padding wow fadeInLeft"
              data-wow-delay="200ms"
              data-wow-duration="1000ms"
            >
              <div className="about-content">
                <h2>
                  <FormattedHTMLMessage id="about.me" />
                </h2>
                <p>
                  <FormattedHTMLMessage id="about.description" />
                </p>
                <ul className="profile-info">
                  <li>
                    <span>
                      <FormattedHTMLMessage id="about.full_name_label" />
                    </span>
                    <FormattedMessage id="about.full_name" />
                  </li>
                  <li>
                    <span>
                      <FormattedHTMLMessage id="about.birth_date_label" />
                    </span>
                    <FormattedMessage id="about.birth_date" />
                  </li>
                  <li>
                    <span>
                      <FormattedHTMLMessage id="about.email_label" />
                    </span>
                    <a
                      href="mailto:hola@fxpc.dev"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "inherit" }}
                    >
                      <FormattedMessage id="about.email" />
                    </a>
                  </li>
                  <li>
                    <span>
                      <FormattedHTMLMessage id="about.website_label" />
                    </span>
                    <a
                      href="https://fxpc.dev"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "inherit" }}
                    >
                      <FormattedMessage id="about.website" />
                    </a>
                  </li>
                </ul>
                <a
                  href={
                    context.locale === "es"
                      ? "/docs/cv_fxpc_lite_es.pdf"
                      : "/docs/cv_fxpc_lite_en.pdf"
                  }
                  className="default-btn"
                  target="_blank"
                >
                  <FormattedMessage id="about.cv_label" />
                  <i className="ti-download"></i>
                </a>
              </div>
            </div>
            <div
              className="col-md-5 sm-padding wow fadeInRight"
              data-wow-delay="200ms"
              data-wow-duration="1000ms"
            >
              <div className="about-bg">
                <Image
                  src={require("../../img/me.png")}
                  webp={require("../../img/me.webp")}
                  alt="logo"
                />
                <div className="dots"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
}
export default AboutSection;
