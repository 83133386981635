import React, { Component } from "react";
import { TiSocialTwitter, TiSocialLinkedin } from "react-icons/ti";
import "./Footer.css";

class Footer extends Component {
  render() {
    return (
      <footer className="footer-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <p>©{new Date().getFullYear()} Francisco Xavier Pérez Chávez </p>
            </div>
            <div className="col-sm-6">
              <div className="footer-social text-right">
                <a
                  href="https://twitter.com/nullRedX"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "inherit" }}
                >
                  <TiSocialTwitter fontSize="25px" />
                </a>
                <a
                  href="https://www.linkedin.com/in/francisco-xavier-p%C3%A9rez-ch%C3%A1vez-02426059/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "inherit" }}
                >
                  <TiSocialLinkedin fontSize="25px" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
