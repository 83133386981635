import React, { Component } from "react";
import { Element } from "react-scroll";
import { FormattedMessage } from "react-intl";
import "./elegant-line-icons.css";
import "./ServicesSection.css";

class ServicesSection extends Component {
  render() {
    return (
      <Element name="services">
        <section
          id="services"
          className="services-section section-padding bd-bottom"
          data-scroll-index="3"
        >
          <div className="container">
            <div
              className="section-heading mb-40 text-center wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1000ms"
            >
              <h2>
                <FormattedMessage id="services.header" />
              </h2>
            </div>
            <div className="row">
              <div
                className="col-md-4 col-sm-6 sm-padding wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1000ms"
              >
                <div className="services-item">
                  <i className="icon-search"></i>
                  <h3>
                    <FormattedMessage id="services.first.header" />
                  </h3>
                  <p>
                    <FormattedMessage id="services.first.detail" />
                  </p>
                  <div className="dots"></div>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-6 sm-padding wow fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1000ms"
              >
                <div className="services-item">
                  <i className="icon-tools"></i>
                  <h3>
                    <FormattedMessage id="services.second.header" />
                  </h3>
                  <p>
                    <FormattedMessage id="services.second.detail" />
                  </p>
                  <div className="dots"></div>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-6 sm-padding wow fadeInUp"
                data-wow-delay="500ms"
                data-wow-duration="1000ms"
              >
                <div className="services-item">
                  <i className="icon-genius"></i>

                  <h3>
                    <FormattedMessage id="services.third.header" />
                  </h3>
                  <p>
                    <FormattedMessage id="services.third.detail" />
                  </p>
                  <div className="dots"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>
    );
  }
}
export default ServicesSection;
