import React, { Component } from "react";
import { Element } from "react-scroll";
import Isotope from "isotope-layout";
import Lightbox from "react-image-lightbox";
import { FormattedMessage } from "react-intl";
import Image from "react-image-webp";
import "react-image-lightbox/style.css";
import "./PortafolioSection.css";

import kts01 from "../../img/kts/kts02.png";
import kts02 from "../../img/kts/kts01.png";
import kts03 from "../../img/kts/kts03.png";

import krt01 from "../../img/krt/krt01.png";
import krt02 from "../../img/krt/krt02.png";
import krt03 from "../../img/krt/krt03.png";

import naturtgy01 from "../../img/naturgy/naturgy01.png";
import naturtgy02 from "../../img/naturgy/naturgy02.png";
import naturtgy03 from "../../img/naturgy/naturgy03.png";

import sectur01 from "../../img/sectur/sectur01.png";
import sectur02 from "../../img/sectur/sectur02.png";
import sectur03 from "../../img/sectur/sectur03.png";

import sipec01 from "../../img/sipec/sipec01.png";
import sipec02 from "../../img/sipec/sipec02.png";
import sipec03 from "../../img/sipec/sipec03.png";

const kts_images = [kts01, kts02, kts03];
const krt_images = [krt01, krt02, krt03];
const naturgy_images = [naturtgy01, naturtgy02, naturtgy03];
const sectur_images = [sectur01, sectur02, sectur03];
const sipec_images = [sipec01, sipec02, sipec03];

const kts_title = (
  <h2>
    <FormattedMessage id="projects.kts.title" />
  </h2>
);

const krt_title = (
  <h2>
    <FormattedMessage id="projects.krt.title" />
  </h2>
);

const naturgy_title = (
  <h2>
    <FormattedMessage id="projects.naturgy.title" />
  </h2>
);

const sectur_title = (
  <h2>
    <FormattedMessage id="projects.sectur.title" />
  </h2>
);

const sipec_title = (
  <h2>
    <FormattedMessage id="projects.sipec.title" />
  </h2>
);

const kts_captions = [
  <FormattedMessage id="projects.kts.first.caption" />,
  <FormattedMessage id="projects.kts.second.caption" />,
  <FormattedMessage id="projects.kts.third.caption" />,
];

const krt_captions = [
  <FormattedMessage id="projects.krt.first.caption" />,
  <FormattedMessage id="projects.krt.second.caption" />,
  <FormattedMessage id="projects.krt.third.caption" />,
];

const naturgy_captions = [
  <FormattedMessage id="projects.naturgy.first.caption" />,
  <FormattedMessage id="projects.naturgy.second.caption" />,
  <FormattedMessage id="projects.naturgy.third.caption" />,
];

const sectur_captions = [
  <FormattedMessage id="projects.sectur.first.caption" />,
  <FormattedMessage id="projects.sectur.second.caption" />,
  <FormattedMessage id="projects.sectur.third.caption" />,
];

const sipec_captions = [
  <FormattedMessage id="projects.sipec.first.caption" />,
  <FormattedMessage id="projects.sipec.second.caption" />,
  <FormattedMessage id="projects.sipec.third.caption" />,
];

class PortafolioSection extends Component {
  constructor(props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.state = {
      filter: "*",
      isOpen: 0,
      index: 0,
      images: [],
      title: "",
      caption: "",
    };

    this.openLightbox = this.openLightbox.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
    this.moveNext = this.moveNext.bind(this);
    this.movePrev = this.movePrev.bind(this);
  }

  openLightbox(box) {
    switch (box) {
      case 1:
        this.setState({
          title: kts_title,
          images: kts_images,
          caption: kts_captions,
        });
        break;
      case 2:
        this.setState({
          title: krt_title,
          images: krt_images,
          caption: krt_captions,
        });
        break;
      case 3:
        this.setState({
          title: naturgy_title,
          images: naturgy_images,
          caption: naturgy_captions,
        });
        break;
      case 4:
        this.setState({
          title: sectur_title,
          images: sectur_images,
          caption: sectur_captions,
        });
        break;
      case 6:
        this.setState({
          title: sipec_title,
          images: sipec_images,
          caption: sipec_captions,
        });
        break;
      default:
        break;
    }
    this.setState({ isOpen: true });
  }

  closeLightbox() {
    this.setState({ isOpen: false });
  }

  moveNext() {
    this.setState((prevState) => ({
      index: (prevState.index + 1) % this.state.images.length,
    }));
  }

  movePrev() {
    this.setState((prevState) => ({
      index:
        (prevState.index + this.state.images.length - 1) %
        this.state.images.length,
    }));
  }

  onFilterChange = (newFilter) => {
    this.setState({ filter: newFilter });
    if (this.iso === undefined) {
      this.iso = new Isotope(".portfolio-items", {
        itemSelector: ".single-item",
        layoutMode: "masonry",
      });
    }
    if (newFilter === "*") {
      this.iso.arrange({ filter: `*` });
    } else {
      this.iso.arrange({ filter: `.${newFilter}` });
    }
  };

  render() {
    let lightbox;
    if (this.state.isOpen) {
      lightbox = (
        <Lightbox
          className="lightbox-size"
          mainSrc={this.state.images[this.state.index]}
          nextSrc={
            this.state.images[(this.state.index + 1) % this.state.images.length]
          }
          prevSrc={
            this.state.images[
              (this.state.index + this.state.images.length - 1) %
                this.state.images.length
            ]
          }
          onCloseRequest={this.closeLightbox}
          onMovePrevRequest={this.movePrev}
          onMoveNextRequest={this.moveNext}
          imageTitle={this.state.title}
          imageCaption={this.state.caption[this.state.index]}
        />
      );
    }
    return (
      <Element name="portfolios">
        {lightbox}
        <section
          id="portfolios"
          className="portfolio-section section-padding bd-bottom"
          data-scroll-index="4"
        >
          <div className="container">
            <div
              className="section-heading text-center mb-40 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1000ms"
            >
              <h2>
                <FormattedMessage id="portafolio.header" />
              </h2>
            </div>
            <div className="portfolio-wrapper">
              <ul className="portfolio-filter text-center mb-30">
                <li
                  className={this.state.filter === "*" ? "active" : ""}
                  data-filter="*"
                  onClick={() => {
                    this.onFilterChange("*");
                  }}
                >
                  <FormattedMessage id="projects.first.filter" />
                </li>
                <li
                  className={this.state.filter === "blockchain" ? "active" : ""}
                  data-filter=".blockchain"
                  onClick={() => {
                    this.onFilterChange("blockchain");
                  }}
                >
                  <FormattedMessage id="projects.second.filter" />
                </li>
                <li
                  className={this.state.filter === "java" ? "active" : ""}
                  data-filter=".java"
                  onClick={() => {
                    this.onFilterChange("java");
                  }}
                >
                  <FormattedMessage id="projects.third.filter" />
                </li>
                <li
                  className={this.state.filter === "postgresql" ? "active" : ""}
                  data-filter=".postgresql"
                  onClick={() => {
                    this.onFilterChange("postgresql");
                  }}
                >
                  <FormattedMessage id="projects.forth.filter" />
                </li>
                <li
                  className={this.state.filter === "postgis" ? "active" : ""}
                  data-filter=".postgis"
                  onClick={() => {
                    this.onFilterChange("postgis");
                  }}
                >
                  <FormattedMessage id="projects.fifth.filter" />
                </li>
                <li
                  className={this.state.filter === "solr" ? "active" : ""}
                  data-filter=".solr"
                  onClick={() => {
                    this.onFilterChange("solr");
                  }}
                >
                  <FormattedMessage id="projects.sixth.filter" />
                </li>
                <li
                  className={this.state.filter === "bpm" ? "active" : ""}
                  data-filter=".bpm"
                  onClick={() => {
                    this.onFilterChange("bpm");
                  }}
                >
                  <FormattedMessage id="projects.seventh.filter" />
                </li>
                <li
                  className={this.state.filter === "angularjs" ? "active" : ""}
                  data-filter=".angularjs"
                  onClick={() => {
                    this.onFilterChange("angularjs");
                  }}
                >
                  <FormattedMessage id="projects.eighth.filter" />
                </li>
                <li
                  className={this.state.filter === "react" ? "active" : ""}
                  data-filter=".react"
                  onClick={() => {
                    this.onFilterChange("react");
                  }}
                >
                  <FormattedMessage id="projects.ninth.filter" />
                </li>
                <li
                  className={this.state.filter === "jsf" ? "active" : ""}
                  data-filter=".jsf"
                  onClick={() => {
                    this.onFilterChange("jsf");
                  }}
                >
                  <FormattedMessage id="projects.tenth.filter" />
                </li>
              </ul>
              <div className="row portfolio-items">
                <div className="col-md-4 col-sm-6 padding-10 single-item blockchain react">
                  <div className="portfolio-box">
                    <Image
                      src={require("../../img/kts/kts.png")}
                      webp={require("../../img/kts/kts.webp")}
                      alt="logo"
                    />
                    <div className="portfolio-content">
                      <h3
                        className="portfolio-content-a"
                        onClick={() => this.openLightbox(1)}
                      >
                        <FormattedMessage id="projects.kts.header" />
                        <span>
                          <FormattedMessage id="projects.kts.type" />
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 padding-10 single-item java bpm postgresql angularjs">
                  <div className="portfolio-box">
                    <Image
                      src={require("../../img/naturgy/naturgy.png")}
                      webp={require("../../img/naturgy/naturgy.webp")}
                      alt="logo"
                    />
                    <div className="portfolio-content">
                      <h3
                        className="portfolio-content-a"
                        onClick={() => this.openLightbox(3)}
                      >
                        <FormattedMessage id="projects.naturgy.header" />
                        <span>
                          <FormattedMessage id="projects.naturgy.type" />
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 padding-10 single-item java solr angularjs">
                  <div className="portfolio-box">
                    <Image
                      src={require("../../img/sectur/sectur.png")}
                      webp={require("../../img/sectur/sectur.webp")}
                      alt="logo"
                    />
                    <div className="portfolio-content">
                      <h3
                        className="portfolio-content-a"
                        onClick={() => this.openLightbox(4)}
                      >
                        <FormattedMessage id="projects.sectur.header" />
                        <span>
                          <FormattedMessage id="projects.sectur.type" />
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 padding-10 single-item java postgresql postgis jsf">
                  <div className="portfolio-box">
                    <Image
                      src={require("../../img/sipec/sct.png")}
                      webp={require("../../img/sipec/sct.webp")}
                      alt="logo"
                    />
                    <div className="portfolio-content">
                      <h3
                        className="portfolio-content-a"
                        onClick={() => this.openLightbox(6)}
                      >
                        <FormattedMessage id="projects.sipec.header" />
                        <span>
                          <FormattedMessage id="projects.sipec.type" />
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>
    );
  }
}
export default PortafolioSection;
