import React, { useContext } from "react";
import Particles from "react-particles-js";
import ReactTyped from "react-typed";
import { Element } from "react-scroll";
import { TiSocialTwitter, TiSocialLinkedin } from "react-icons/ti";
import { FormattedMessage } from "react-intl";
import { isWebpSupported } from "react-image-webp/dist/utils";
import { Context } from "../../components/Wrapper";
import "./HeroSection.css";

function HeroSection() {
  const context = useContext(Context);
  return (
    <Element name="home">
      <div style={{ position: "relative" }}>
        <header
          className="hero-section d-flex align-items-center bd-bottom"
          data-scroll-index="0"
          style={{
            backgroundImage: `url(${
              isWebpSupported()
                ? require("../../img/portada.webp")
                : require("../../img/portada.jpg")
            })`,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "8px",
              right: "16px",
            }}
          >
            <select value={context.locale} onChange={context.selectLanguage}>
              <option value="en">English</option>
              <option value="es">Español</option>
            </select>
          </div>

          <Particles
            className="particles-js"
            params={{
              particles: {
                number: {
                  value: 80,
                  density: {
                    enable: true,
                    value_area: 800,
                  },
                },
                color: {
                  value: "#ddd",
                },
                shape: {
                  type: "circle",
                  stroke: {
                    width: 0,
                    color: "#fff",
                  },
                  polygon: {
                    nb_sides: 5,
                  },
                  image: {
                    src: "img/github.svg",
                    width: 100,
                    height: 100,
                  },
                },
                opacity: {
                  value: 0.5,
                  random: false,
                  anim: {
                    enable: false,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false,
                  },
                },
                size: {
                  value: 5,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 40,
                    size_min: 0.1,
                    sync: false,
                  },
                },
                line_linked: {
                  enable: true,
                  distance: 150,
                  color: "#fff",
                  opacity: 0.2,
                  width: 1,
                },
                move: {
                  enable: true,
                  speed: 6,
                  direction: "none",
                  random: false,
                  straight: false,
                  out_mode: "out",
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                  },
                },
              },
              interactivity: {
                detect_on: "canvas",
                events: {
                  onhover: {
                    enable: true,
                    mode: "repulse",
                  },
                  onclick: {
                    enable: true,
                    mode: "push",
                  },
                  resize: true,
                },
                modes: {
                  grab: {
                    distance: 400,
                    line_linked: {
                      opacity: 1,
                    },
                  },
                  bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 8,
                    speed: 3,
                  },
                  repulse: {
                    distance: 200,
                  },
                  push: {
                    particles_nb: 4,
                  },
                  remove: {
                    particles_nb: 2,
                  },
                },
              },
              retina_detect: true,
              config_demo: {
                hide_card: false,
                background_color: "#b61924",
                background_image: "",
                background_position: "50% 50%",
                background_repeat: "no-repeat",
                background_size: "cover",
              },
            }}
          />
          <div className="container">
            <div className="hero-content text-center">
              <h4>
                <FormattedMessage id="hero.gretting" />
              </h4>
              <h1>
                <FormattedMessage id="hero.name" />
              </h1>
              <h4>
                <FormattedMessage id="hero.typed" />{" "}
                <ReactTyped
                  strings={["Fullstack.", "Engineer.", "Developer."]}
                  typeSpeed={150}
                  loop
                />
              </h4>
            </div>
            <ul className="hero-social">
              <li>
                <a
                  href="https://twitter.com/nullRedX"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "inherit" }}
                >
                  <TiSocialTwitter fontSize="25px" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/francisco-xavier-p%C3%A9rez-ch%C3%A1vez-02426059/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "inherit" }}
                >
                  <TiSocialLinkedin fontSize="25px" />
                </a>
              </li>
            </ul>
          </div>
        </header>
      </div>
    </Element>
  );
}

export default HeroSection;
