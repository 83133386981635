import React, { Component } from "react";
import { Link as LinkScroll } from "react-scroll";
import { FormattedHTMLMessage } from "react-intl";
import Image from "react-image-webp";
import "./Sidebar.css";

class Sidebar extends Component {
  componentDidMount() {
    document.body.classList.add("body-fixed-sidebar");
  }

  render() {
    return (
      <div
        className={
          this.props.open
            ? "dl-fixed-sidebar dl-sidebar-left dl-fixed-sidebar-open"
            : "dl-fixed-sidebar dl-sidebar-left"
        }
      >
        <div className="dl-header-container">
          <div className="dl-fixed-menu-wrap">
            <div className="logo">
              <h1>
                <a href="/">
                  <Image
                    src={require("../img/turing.png")}
                    webp={require("../img/turing.webp")}
                    alt="logo"
                  />
                </a>
              </h1>
            </div>
            <nav className="dl-menu-fixed">
              <ul>
                <li>
                  <LinkScroll
                    activeClass="active"
                    to="home"
                    spy={true}
                    smooth={true}
                    duration={1200}
                  >
                    <FormattedHTMLMessage id="menu.home" />
                  </LinkScroll>
                </li>
                <li>
                  <LinkScroll
                    activeClass="active"
                    to="about"
                    spy={true}
                    smooth={true}
                    duration={1200}
                  >
                    <FormattedHTMLMessage id="menu.about" />
                  </LinkScroll>
                </li>
                <li>
                  <LinkScroll
                    activeClass="active"
                    to="resume"
                    spy={true}
                    smooth={true}
                    duration={1200}
                  >
                    <FormattedHTMLMessage id="menu.resume" />
                  </LinkScroll>
                </li>
                <li>
                  <LinkScroll
                    activeClass="active"
                    to="services"
                    spy={true}
                    smooth={true}
                    duration={1200}
                  >
                    <FormattedHTMLMessage id="menu.services" />
                  </LinkScroll>
                </li>
                <li>
                  <LinkScroll
                    activeClass="active"
                    to="portfolios"
                    spy={true}
                    smooth={true}
                    duration={1200}
                  >
                    <FormattedHTMLMessage id="menu.projects" />
                  </LinkScroll>
                </li>
                <li>
                  <LinkScroll
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    duration={1200}
                  >
                    <FormattedHTMLMessage id="menu.contact" />
                  </LinkScroll>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
export default Sidebar;
