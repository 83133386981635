import React, { Component } from "react";
import Sidebar from "./Sidebar/Sidebar";
import SideContent from "./SideContent/SideContent";
import Burger from "./Sidebar/Burger";
import ClickOutHandler from "react-onclickout";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import "./App.css";

const firebaseConfig = {
  apiKey: "AIzaSyBiPxNPk7J7SjhJ6oluU_2Yl7zMN-HDMZM",
  authDomain: "fxpc-web.firebaseapp.com",
  projectId: "fxpc-web",
  storageBucket: "fxpc-web.appspot.com",
  messagingSenderId: "438766080436",
  appId: "1:438766080436:web:2656bce50ac2a05d46a2e1",
  measurementId: "G-D0V80RPPSZ",
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
  }

  componentDidMount() {
    document.body.classList.add("loaded");
  }

  toggleMenu = () => {
    this.setState({ open: !this.state.open });
  };
  toggleMenuOutside = () => {
    if (this.state.open) this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <div className="site-preloader-wrap">
          <div className="spinner"></div>
        </div>
        <Sidebar open={this.state.open} />
        <ClickOutHandler onClickOut={this.toggleMenuOutside}>
          <Burger open={this.state.open} toggleMenu={this.toggleMenu} />
        </ClickOutHandler>
        <div className="dl-fixedsidebar-bg-overlay"></div>
        <SideContent />
      </div>
    );
  }
}

export default App;
