import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import English from "../translations/en.json";
import Spanish from "../translations/es.json";

export const Context = React.createContext();
const local = navigator.language.split("-")[0];

let lang;
console.log(local);

if (local === "en") {
  lang = English;
} else if (local === "es") {
  lang = Spanish;
}

const Wrapper = (props) => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);
  function selectLanguage(e) {
    const newLocale = e.target.value;
    setLocale(newLocale);
    if (newLocale === "en") {
      setMessages(English);
    } else if (newLocale === "es") {
      setMessages(Spanish);
    }
  }
  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};
export default Wrapper;
