import React, { Component } from "react";
import { TiBookmark } from "react-icons/ti";
import { Element } from "react-scroll";
import { FormattedMessage } from "react-intl";
import "./ResumeSection.css";

class ResumeSection extends Component {
  render() {
    return (
      <Element name="resume">
        <section
          id="resume"
          className="resume-section section-padding bd-bottom"
          data-scroll-index="2"
        >
          <div className="container">
            <div className="row">
              <div
                className="col-md-6 sm-padding wow fadeInLeft"
                data-wow-delay="200ms"
                data-wow-duration="1000ms"
              >
                <div className="section-heading mb-40">
                  <h2>
                    <FormattedMessage id="resume.header" />
                  </h2>
                </div>
                <ul className="exp-list">
                  <li>
                    <div className="exp-date">
                      <i>
                        <TiBookmark />
                      </i>
                      <span>
                        <FormattedMessage id="resume.fifth.date" />
                      </span>
                    </div>
                    <div className="exp-content">
                      <h4>
                        <FormattedMessage id="resume.fifth.company" />{" "}
                        <span>
                          <FormattedMessage id="resume.fifth.job" />
                        </span>
                      </h4>
                      <p>
                        <FormattedMessage id="resume.fifth.description" />
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="exp-date">
                      <i>
                        <TiBookmark />
                      </i>
                      <span>
                        <FormattedMessage id="resume.forth.date" />
                      </span>
                    </div>
                    <div className="exp-content">
                      <h4>
                        <FormattedMessage id="resume.forth.company" />{" "}
                        <span>
                          <FormattedMessage id="resume.forth.job" />
                        </span>
                      </h4>
                      <p>
                        <FormattedMessage id="resume.forth.description" />
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="exp-date">
                      <i>
                        <TiBookmark />
                      </i>
                      <span>
                        <FormattedMessage id="resume.first.date" />
                      </span>
                    </div>
                    <div className="exp-content">
                      <h4>
                        <FormattedMessage id="resume.first.company" />{" "}
                        <span>
                          <FormattedMessage id="resume.first.job" />
                        </span>
                      </h4>
                      <p>
                        <FormattedMessage id="resume.first.description" />
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="exp-date">
                      <i>
                        <TiBookmark />
                      </i>
                      <span>
                        <FormattedMessage id="resume.second.date" />
                      </span>
                    </div>
                    <div className="exp-content">
                      <h4>
                        <FormattedMessage id="resume.second.company" />
                        <span>
                          <FormattedMessage id="resume.second.job" />
                        </span>
                      </h4>
                      <p>
                        <FormattedMessage id="resume.second.description" />
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                className="col-md-6 sm-padding wow fadeInRight"
                data-wow-delay="200ms"
                data-wow-duration="1000ms"
              >
                <div className="skill-wrap">
                  <div className="section-heading mb-40">
                    <h2>
                      <FormattedMessage id="resume.skills_label" />
                    </h2>
                  </div>
                  <ul className="skill-items">
                    <li className="skill-item">
                      <h5>
                        <FormattedMessage id="resume.sixth.skill" />
                      </h5>
                      <div className="progress">
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-delay="400ms"
                          data-wow-duration="2000ms"
                          role="progressbar"
                          style={{ width: "70%" }}
                        >
                          <span>70%</span>
                        </div>
                      </div>
                    </li>
                    <li className="skill-item">
                      <h5>
                        <FormattedMessage id="resume.first.skill" />
                      </h5>
                      <div className="progress">
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-delay="0ms"
                          data-wow-duration="2000ms"
                          role="progressbar"
                          style={{ width: "80%" }}
                        >
                          <span>80%</span>
                        </div>
                      </div>
                    </li>
                    <li className="skill-item">
                      <h5>
                        <FormattedMessage id="resume.second.skill" />
                      </h5>
                      <div className="progress">
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-delay="200ms"
                          data-wow-duration="2000ms"
                          role="progressbar"
                          style={{ width: "70%" }}
                        >
                          <span>70%</span>
                        </div>
                      </div>
                    </li>
                    <li className="skill-item">
                      <h5>
                        <FormattedMessage id="resume.third.skill" />
                      </h5>
                      <div className="progress">
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-delay="300ms"
                          data-wow-duration="2000ms"
                          role="progressbar"
                          style={{ width: "60%" }}
                        >
                          <span>60%</span>
                        </div>
                      </div>
                    </li>
                    <li className="skill-item">
                      <h5>
                        <FormattedMessage id="resume.forth.skill" />
                      </h5>
                      <div className="progress">
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-delay="400ms"
                          data-wow-duration="2000ms"
                          role="progressbar"
                          style={{ width: "50%" }}
                        >
                          <span>50%</span>
                        </div>
                      </div>
                    </li>
                    <li className="skill-item">
                      <h5>
                        <FormattedMessage id="resume.fifth.skill" />
                      </h5>
                      <div className="progress">
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-delay="400ms"
                          data-wow-duration="2000ms"
                          role="progressbar"
                          style={{ width: "60%" }}
                        >
                          <span>60%</span>
                        </div>
                      </div>
                    </li>
                    <li className="skill-item">
                      <h5>
                        <FormattedMessage id="resume.seventh.skill" />
                      </h5>
                      <div className="progress">
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-delay="400ms"
                          data-wow-duration="2000ms"
                          role="progressbar"
                          style={{ width: "70%" }}
                        >
                          <span>70%</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>
    );
  }
}
export default ResumeSection;
