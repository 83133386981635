import React, { useState } from "react";
import { Element } from "react-scroll";
import { FormattedMessage } from "react-intl";
import emailjs from "emailjs-com";
import { Alert } from "react-bootstrap";
import "./ContactSection.css";
import ReCAPTCHA from "react-google-recaptcha";

function ContactSection() {
  const [messageHeader, setMessageHeader] = useState("");
  const [message, setMessage] = useState("");
  const [color, setColor] = useState({ color: "#155724" });

  const [captcha, setCapcha] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    openAlert: false,
    successEmail: false,
  });
  const recaptchaRef = React.createRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (captcha) {
      const { name, email, message } = formValues;
      let templateParams = {
        from_name: name,
        to_name: "hola@fxpc.dev",
        email_sender: email,
        message_html: message,
      };

      emailjs
        .send(
          "service_qu6bvxq",
          "template_95t81y8",
          templateParams,
          "cywkUoT1SuwQ63hdp"
        )
        .then(
          (response) => {
            setMessageHeader("contact.success.header");
            setMessage("contact.success.message");
            setColor({ color: "#155724" });
            console.log(response);
            setFormValues({
              ...formValues,
              successEmail: true,
              openAlert: true,
            });
          },
          (error) => {
            setMessageHeader("contact.fail.header");
            setMessage("contact.fail.message");
            setColor({ color: "#721c24" });
            console.log(error);
            setFormValues({
              ...formValues,
              successEmail: false,
              openAlert: true,
            });
          }
        );
    } else {
      setMessageHeader("contact.captcha.header");
      setMessage("contact.captcha.message");
      setColor({ color: "#155724" });
      setFormValues({
        ...formValues,
        successEmail: false,
        openAlert: true,
      });
    }
  };

  const handleChange = (param, e) => {
    setFormValues({ ...formValues, [param]: e.target.value });
  };

  const handleAlertDismiss = () => {
    setFormValues({ ...formValues, openAlert: false });
  };

  function onChangeCaptcha() {
    setCapcha(true);
  }

  return (
    <Element name="contact">
      <section
        id="contact"
        className="contact-section section-padding"
        data-scroll-index="6"
      >
        <div className="container">
          <div
            className="section-heading mb-40 text-center wow fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1000ms"
          >
            <h2>
              <FormattedMessage id="conatct.header" />
            </h2>
          </div>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <form
                onSubmit={handleSubmit.bind(this)}
                className="row contact-form text-center"
              >
                <div className="form-group col-lg-6">
                  <FormattedMessage id="contact.name">
                    {(placeholder) => (
                      <input
                        type="text"
                        value={formValues.name}
                        onChange={handleChange.bind(this, "name")}
                        className="form-control"
                        id="name"
                        placeholder={placeholder}
                        required
                      />
                    )}
                  </FormattedMessage>
                </div>
                <div className="form-group col-lg-6">
                  <FormattedMessage id="contact.email">
                    {(placeholder) => (
                      <input
                        type="email"
                        value={formValues.email}
                        onChange={handleChange.bind(this, "email")}
                        className="form-control"
                        id="email"
                        placeholder={placeholder}
                        required
                      />
                    )}
                  </FormattedMessage>
                </div>
                <div className="form-group col-lg-12">
                  <FormattedMessage id="contact.message">
                    {(placeholder) => (
                      <textarea
                        className="form-control message"
                        id="message"
                        name="message"
                        value={formValues.message}
                        onChange={handleChange.bind(this, "message")}
                        placeholder={placeholder}
                        required
                      ></textarea>
                    )}
                  </FormattedMessage>
                </div>
                <div className="form-group col-lg-12">
                  <div style={{ display: "inline-block", textAlign: "center" }}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Ld4XQsgAAAAAOySFb-2TcPiHpy-Ld2jHuY5cWQ8"
                      onChange={onChangeCaptcha}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-12">
                  <button id="submit" className="default-btn" type="submit">
                    <FormattedMessage id="contact.send" />
                  </button>
                </div>

                <Alert
                  className="form-group col-lg-12"
                  variant={formValues.successEmail ? "success" : "danger"}
                  show={formValues.openAlert}
                  dismissible
                  onClose={handleAlertDismiss}
                >
                  <Alert.Heading>
                    <FormattedMessage id={messageHeader} />
                  </Alert.Heading>
                  <hr />
                  <p style={color}>
                    <FormattedMessage id={message} />
                  </p>
                </Alert>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
}
export default ContactSection;
