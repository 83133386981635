import React, { Component } from "react";
import HeroSection from "./HeroSection/HeroSection.js";
import AboutSection from "./AboutSection/AboutSection.js";
import ResumeSection from "./ResumeSection/ResumeSection.js";
import ServicesSection from "./ServicesSection/ServicesSection.js";
import PortafolioSection from "./PortafolioSection/PortafolioSection.js";
import ContactSection from "./ContactSection/ContactSection.js";
import Footer from "./Footer/Footer.js";
import Image from "react-image-webp";
import WOW from "wow.js";

import "../Sidebar/Sidebar.css";
import "./SideContent.css";

class SideContent extends Component {
  componentDidMount() {
    const wow = new WOW({
      mobile: false,
      offset: 0,
    });
    wow.init();
  }

  render() {
    return (
      <div className="dl-side-content">
        <div className="logo">
          <h1>
            <a href="/">
              <Image
                src={require("../img/turing.png")}
                webp={require("../img/turing.webp")}
                alt="logo"
              />
            </a>
          </h1>
        </div>
        <div className="content-lines-wrapper">
          <div className="content-lines-inner">
            <div className="content-lines"></div>
          </div>
        </div>
        <HeroSection />
        <AboutSection />
        <ResumeSection />
        <ServicesSection />
        <PortafolioSection />
        <ContactSection />
        <Footer />
      </div>
    );
  }
}

export default SideContent;
