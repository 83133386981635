import React, { Component } from "react";
import "../Sidebar/Sidebar.css";

class Burger extends Component {
  render() {
    return (
      <div
        onClick={() => this.props.toggleMenu()}
        className={
          this.props.open ? "dl-burger-menu dl-menu-open" : "dl-burger-menu"
        }
      >
        <span>
          <div className="dl-line-menu dl-line-half dl-first-line"></div>
          <div className="dl-line-menu"></div>
          <div className="dl-line-menu dl-line-half dl-last-line"></div>
        </span>
      </div>
    );
  }
}
export default Burger;
